.screen-shots {
  width: 80%;
  height: 30%;
  object-fit: cover;
  margin: 10px;
  box-shadow: 1px 1px 10px rgb(177, 176, 176);
  
}

.links {
  text-decoration: none;
  text-align: center;
  padding-top: 23vh;
  margin-bottom: 2rem;

}


.link {
  margin: 3rem;
  text-decoration: none;
  color: black;
  cursor: pointer;
  font-weight: bold;
 
}
.darkLinks {
  margin: 3rem;
  text-decoration: none;
  color: white;
  cursor: pointer;
  font-weight: bold;
  padding-top: 23vh;
}
.photo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.desc {
  margin-left: 10vw;
  margin-right: 10vw;
}
.descDark {
  margin-left: 10vw;
  margin-right: 10vw;
  color: white;
}


a {
	position: relative;
	padding: 0.2em 0;
}
a::after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 0.16em;
	background-color: black;
	opacity: 0;
	transition: opacity 300ms, transform 300ms;
}
a::after {
	opacity: 1;
	transform: scale(0);
	transform-origin: center;
}

a:hover::after,
a:focus::after{
	transform: scale(1);
}



@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@1,400;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Belleza&display=swap');

body {
  font-family: 'Belleza', sans-serif;
  background-color: rgb(41, 17, 82);
  margin-bottom:-40px;
  
}

.me-pic {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50vw;
  
}
.bio {
  text-align: center;
  font-weight: 600;
  padding-top: 23vh;
  margin-top: 0px;
}
.darkBio {
  text-align: center;
  font-weight: 600;
  color: white;
  padding-top: 23vh;
  margin-top: 0px;
}
.linkBio {
  text-align: center;
  font-weight: 600;
  
  margin-top: 0px;
}
.darkBioLink {
  text-align: center;
  font-weight: 600;
  color: white;
  
  margin-top: 0px;
}

.abilities {
  display: flex;
  justify-content: space-around;
}


.lightButton {
  border: none;
  background-color: transparent;
  color: black;
  cursor: pointer;
  margin-right: 30px;
  font-size: 1.5rem;
}
.darkButton {
  border: none;
  background-color: transparent;
  color: white;
  cursor: pointer;
  margin-right: 30px;
  font-size: 1.5rem;
}

.lightButton:hover {
  color: rgb(157, 105, 225);
}
.darkButton:hover {
  color:rgb(239, 143, 17)
}

ul {
  list-style-type: none;
}






.aboutDark {
  color: white;
  padding-top: 20vh;
}
.more-stuff {
  padding-top: 20vh;
}

.skills {
  background-color: aliceblue;
  width: 15rem;
  height: 20rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 20px;
  margin: 10px;
}
.school {
  background-color: aliceblue;
  width: 18rem;
  height: 10rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 20px;
  margin: 10px;
}

.UX {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: aliceblue;
  padding: 4px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 20px;
  margin-left: 50px;
  margin-right: 50px;
}
.abilities {
  justify-content: center;

}
.darkSkills {
  
  background-color: rgb(52, 16, 130);
  width: 15rem;
  height: 20rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 20px;
  margin: 10px;
}

.darkSchool {
  background-color: rgb(52, 16, 130);
  width: 18rem;
  height: 10rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 20px;
  margin: 10px;
}
.darkUX {
  margin-left: 50px;
  margin-right: 50px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: rgb(52, 16, 130);
  padding: 4px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 20px;
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@1,400;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Federo&display=swap');

.header {
  /* border-bottom: 2px solid black; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: fixed;
  background-color: beige;
  z-index: 100;
  /* margin-top: -4vh; */
  
}
.name {
  font-family: 'Federo', sans-serif;
  cursor: pointer;
  text-decoration: none;
  color: black;
  /* text-align: center; */
  font-size: 2.5rem;
  /* margin-bottom: 10px; */
  margin-left: 2rem;
}
.projects-box {
  margin-left: 2rem;

}



.menu-button {
  color: black;
  font-size: 1rem;
  
 margin-bottom: 6px;
 font-family: 'Belleza', sans-serif;
}

.more {
  border: none;
  background-color: transparent;
  font-family: 'Belleza', sans-serif;
  font-weight: 600;
  
  cursor: pointer;
}
.darkName {
  font-family: 'Federo', sans-serif;
  cursor: pointer;
  text-decoration: none;
  color: white;
  font-size: 2.5rem;
  
  margin-left: 2rem;
}
.darkHeader {
  /* border-bottom: 2px solid white; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 100;
  
  position: fixed;
  background-color: rgb(52, 16, 130);
}
.darkMenu {
  color: white;
  font-size: 1rem;
  margin-bottom: 6px;
  font-family: 'Belleza', sans-serif;


}
.about-me-link {
  font-weight: 500;
  
}
.name-menu {
  display:flex;
  justify-content: space-between;
  align-items: center;
}


